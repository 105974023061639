import {useOidc} from "@axa-fr/react-oidc";
import {useEffect} from "react";

const ConnectLogout = () => {

    alert("logout");

    const {logout} = useOidc();

    useEffect(() => {
        alert("logout");

        console.log("approval.... logout");
        const messageChannel = new BroadcastChannel("approval_channel");
        messageChannel.postMessage("logout");

        window.postMessage("logout", "*");

        localStorage.removeItem("oidc.login.default");
        localStorage.removeItem("oidc.server:https://connect.identity.stagaws.visma.com");
        sessionStorage.removeItem("oidc.tabId.default");

        logout();
    });


    return null;
}
export default ConnectLogout;