// actions creators for redux store
import {DIFFERENT_USER, LOGGED_IN_TYPE, LOGGED_OUT_TYPE, SHOW_ERROR_MESSAGE, STARTUP_ERROR} from '../store/actionTypes';
import {persistor} from '../store/Store.component';

// import as *, so we can safely mock in tests
import * as Api from 'utils/api/api';
import * as OdpApi from 'utils/api/odp.api';
import {requestLanguageChange} from '../translations/translations.action';
import {getCurrentVersion} from 'utils/getVersion.function';
import {saveVersion, startApp} from '../startup/appStart.action';
import {navigateToErrorPage, switchUserContextRoute} from '../router/router.function.js';
import * as logger from 'utils/logger.function';
import {
    getHydrated,
    getNumberOfConnectionAttempts,
    getUsersCurrentContextId,
    getUsersCurrentLanguage,
    getUsersData,
    getUsersId,
    getVersion
} from '../store/application.reducers';
import {cleanStorage} from 'utils/cleanLocalStorage';
import {handleError} from 'utils/errorHandle.function';
import { launchDarkly, LaunchDarklyContextKind } from "utils/launchDarkly";
import {UNAUTHORIZED_STATUS} from "utils/constants";
import {loadTasks} from "components/mytasks/myTasks.action.js";

function getURLParameter(contextPath) {
    const params = new URLSearchParams(window.location.search);
    return params.get(contextPath);
}

const TIME_TO_RECONNECT = 1000;
const MAX_TIME_TO_RECONNECT = 6;

/**
 * asynchronous action .. wraps requesting data for displaying the application (like data about user, his rights, preferences, ..),
 *  emits data into store upon success
 * @returns resolved promise in case of success
 */
export function loadUserData() {

    return function (dispatch, getState) {
        cleanStorage();
        /* ------------- loading part --------- */
        let state = getState();
        // get the version of bundle, we're running from, and store it in the store so we can check against it later
        const bundleVersion = getCurrentVersion();
        const hydrated = getHydrated(state);
        const version = getVersion(state);

        const currentUser = getUsersId(state);
        const usersData = getUsersData(state);
        const currentContextId = getUsersCurrentContextId(state);
        const currentLanguage = getUsersCurrentLanguage(state);
        let urlContextID = getURLParameter(decodeURIComponent("contextSelector:switchContext"));
        window.currentContext = urlContextID || currentContextId;
        logger.debug("currentContextId : " + currentContextId + " window.currentContext :" + window.currentContext);

        // race condition check! we tried to dispatch action into store even though it's not hydrated yet -  should not happen
        // if we've implemented AppInitialization.component correctly
        if (!hydrated) {
            logger.error("redux.loadUserData", "race condition, action executed before store hydration!");
            navigateToErrorPage();
            return;
        }

        //we now have the web socket connection
        //dispatch(loadTasks());

        // and process user's data
        return Api.getUserData().then(async (response) => {
                /* ------------- deciding part  ----------- */
                let loadUserData = false; // flag to update data about user in store
                let loadTranslations = false; // flag to request translations
                let changedVersion = false; // flag that version of application changed from the last check
                let changedUser = false;

                // The context in the response might be different from the one requested, id the user doesn't have permission
                // to the requested context
                window.currentContext = response.userConfiguration.currentContext;

               // window.csrfToken = response.csrfToken;
              //  window.sessionHash = response.sessionHash;

                // nothing in store about user yet - first login
                const firstLogin = !usersData;

                // handling startup data
                if (firstLogin) {
                    logger.debug("first login detected");
                    loadUserData = true;
                    // he might even log out and log in as different user! which invalidates for example all data related to tasks, so we have to cleanup the store
                } else if (currentUser !== response.userConfiguration.currentUserId) {
                    logger.debug("user came with different userId, cleaning the store (store: " + currentUser + ", response: " + response.userConfiguration.currentContext + ")");
                    changedUser = true;
                    loadUserData = true;
                } else if (response.userConfiguration.currentContext !== currentContextId) {
                    logger.debug("change of the context (store: " + currentContextId + ", response: " + response.userConfiguration.currentContext + ")");
                    loadUserData = true;
                } else {
                    logger.debug("user returned with the same context");
                    // update data in store, there is bunch of data inside startUp, which might have been updated
                    loadUserData = true;
                }

                // handling version change, has to be before translations!
                if (version !== bundleVersion) {
                    changedVersion = true;
                    logger.debug("app version change detected (store: " + version + ", bundle: " + bundleVersion + ")");
                }

                // handling translations
                // either first login, or user changed his preference, or new version was uploaded in the meantime
                if (firstLogin || response.userConfiguration.localeString !== currentLanguage || changedVersion) {
                    loadTranslations = true;
                }

                /* ----------- execution part ---------- */
                // action for reducers to clean the state if necessary
                if (changedUser) {
                    dispatch(differentUserLogged());
                }

                if (changedVersion) {
                    // dispatch this at first, we need to erase old dictionaries
                    dispatch(saveVersion(bundleVersion));
                }

                if (loadUserData) {
                    if (response.loggedInUser) {
                        const userContext = configureLaunchDarklyUser(response.loggedInUser, response.userConfiguration.countryCode);
                        await launchDarkly.createClient(userContext);

                        window.snowplow('setUserId', userContext.key); // Create a user id. User Id will be anonymized.
                    }

                    await launchDarkly.setCompany(response.userConfiguration.currentCompany);

                    dispatch(saveStartupData(response));
                }

                if (loadTranslations) {
                    // we always need english version, since it's fallback for any other language
                    dispatch(requestLanguageChange('en', changedVersion));
                    // and user's language, if differs
                    if (response.userConfiguration.localeString !== 'en') {
                        dispatch(requestLanguageChange(response.userConfiguration.localeString, changedVersion));
                    }
                }

                // TODO might wait with dispatching startApp after language is loaded?
                dispatch(startApp());
            },
            error => {
                const connectionAttempts = getNumberOfConnectionAttempts(state);
                dispatch(handleLoginError(error, connectionAttempts));
            });
    }
}

function handleLoginError(error, connectionAttempts) {
    return function (dispatch) {
        dispatch(startupError());
        if (connectionAttempts < MAX_TIME_TO_RECONNECT) {
            setTimeout(() => {
                dispatch(loadUserData());
            }, (connectionAttempts * TIME_TO_RECONNECT));
        } else {
            //don't navigate if it's an unauthorized error
            if (error.status !== UNAUTHORIZED_STATUS)
                navigateToErrorPage();
        }
    }
}

function configureLaunchDarklyUser(loggedInUser, countryCode) {
    return {
        "kind": LaunchDarklyContextKind.USER,
        "key": loggedInUser.connectUserId || `user-${loggedInUser.id}`,
        "firstName": loggedInUser.firstName,
        "lastName": loggedInUser.lastName,
        "email": loggedInUser.emailAddress,
        "country": countryCode
    };
}

/**
 * asynchronous action handling change of context by user
 * @param newContextId
 */
export function switchUserContext(newContextId) {

    return function (dispatch, getState) {

        let state = getState();
        logger.debug("user changing context (from: " + getUsersCurrentContextId(state) + ", to: " + newContextId + ")");
        window.currentContext = newContextId;
        // return promise, caller can report the error
        return Api.changeUserContext(newContextId).then(
            // for now, do the same as for logging in, making oportunity for changes in the future
            async (response) => {
                await launchDarkly.setCompany(response.userConfiguration.currentCompany);
                let currentContext = response.userConfiguration.currentContext;
                window.currentContext = currentContext;
                // dispatch event with all the details for logged user to the store
                dispatch(saveStartupData(response));
                // request users translation
                dispatch(requestLanguageChange(response.userConfiguration.localeString));

                if (currentContext) {
                    switchUserContextRoute(currentContext, response.userConfiguration.userRoles);
                }

            }, error => {
                handleError(error);
            });
    }
}

export function saveStartupData(userData) {
    return {
        type: LOGGED_IN_TYPE,
        data: userData
    };
}

export function logout() {
    return {
        type: LOGGED_OUT_TYPE
    }
}

export function differentUserLogged() {
    return {
        type: DIFFERENT_USER
    }
}

export function startupError() {
    return {
        type: STARTUP_ERROR
    }
    //should do a cleanup of all the store here
}

export function showErrorMessage(error) {
    return {
        type: SHOW_ERROR_MESSAGE,
        data: error
    };
}

export function hideErrorMessage() {
    return {
        type: SHOW_ERROR_MESSAGE,
        data: false
    };
}

/**
 * asynchronous action dispatches action to clear the store and redirects to ODP login form to cancel server session
 * @param purgeStore
 * @returns {Function}
 */
export function logoutUser(purgeStore = false) {

    return async function (dispatch) {
        if (purgeStore) {
            // store reset .. in case we change the structure in store
            await persistor.purge();
        } else {
            // in case you need more things to be removed from store upon logout, you should map appropriate reducers
            // to logout action type, as it's done for example in translations.reducer.js
            dispatch(logout());
        }

        sessionStorage.clear();
        // clear backend session and redirect to login form
        OdpApi.logout();

    }
}

export function updatePreferences(preferences) {
    return function (dispatch) {
        Api.updateUserSettings(preferences).then(
            () => {
                dispatch(loadUserData());
            }, (error) => {
                handleError(error);
            }
        );
    }
}
