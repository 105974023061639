import React, {useState} from 'react';
import ContextSelectorBox from './ContextSelectorBox.component';
import * as OdpApi from '../../utils/api/odp.api';
import {useOidc} from "@axa-fr/react-oidc";


const MenuUserBox = (props) => {

    const [userBoxOpen, setUserBoxOpen] = useState(false);
    const {logout} = useOidc();

    const onBlurUserBox = (e) => {
        // without check for currentTarget/activeElement we would also unBlur for clicks inside dropdown
        // inspiration from here: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
        // also, we have to add tabIndex to the component, to be focusable, otherwise document.activeElement would be 'body'

        const currentTarget = e.currentTarget;
        e.stopPropagation();
        setTimeout(function () {
            if (!currentTarget.contains(document.activeElement)) {
                setUserBoxOpen(0);
            }
        }, 0);
    }

    /**
     * this will be executed from children, when user change the context, we need to execute redux action and collapse userbox
     * @param newContext
     */
    const onSelection = (newContext) => {
        // collapse selector
        setUserBoxOpen(false);

        // call redux action mounted in parent
        // TODO returns promise, add error/positive handling?
        props.switchUserContext(newContext);
    }

    const doLogout = () => {
        const messageChannel = new BroadcastChannel("approval_channel");
        messageChannel.postMessage("logout");

        logout();
        //props.logoutUser();
    }


    let userIcon = "dropdown user-dropdown";
    if (userBoxOpen)
        userIcon += " open";
    if (props.collapsed)
        userIcon += " icon";
    // unfortunately styles expect li>.dropdown-toggle so we cannot move <li> to parent component
    return (
        // TODO icon only if collapsed style
        <li className={userIcon}
            onBlur={onBlurUserBox}>

            <a className="dropdown-toggle button-context no-caret" role="button"
               href={"#"}
               aria-expanded={userBoxOpen}
               label={props.loggedInData.firstName + props.loggedInData.lastName}
               data-cy={props.loggedInData.firstName + props.loggedInData.lastName}
               onClick={() =>  setUserBoxOpen( !userBoxOpen)}>
                {props.loggedInData.firstName + " " + props.loggedInData.lastName}
                <span className="vismaicon vismaicon-menu vismaicon-dynamic vismaicon-user"/>
                <small className="selectedContext">{props.userData.companyName}</small>
                <span className="caret"></span>
            </a>
            <ul className="dropdown-menu company-selection border-0" role="menu">
                <li className="user-details-area clear" role="menuitem">
                    <div title={props.loggedInData.emailAddress}>
                        <div className="vismaicon-user-placeholder vismaicon py-4"
                             alt={props.loggedInData.firstName + " " + props.loggedInData.lastName}
                        />
                        <div className="user-text">
                            <span>{props.loggedInData.firstName} {props.loggedInData.lastName}</span>
                            <span
                                className="text-disabled">{props.loggedInData.emailAddress}</span>
                        </div>
                    </div>
                </li>

                <li className="divider"/>

                <li className="company-selection-area" role="menuitem">
                    <ContextSelectorBox
                        currentContextId={props.currentContextId}
                        onSelection={onSelection}
                        parentExpanded={userBoxOpen}
                        translate={props.translate}
                        userData={props.userData}
                    />
                </li>

                <li className="divider"/>
                <li className="px-3">
                    <a className="btn btn-default pull-right"
                       href={OdpApi.myDetailsUrl()}>{props.translate('menu.userBox.myDetails')}</a>
                </li>
                <li className="divider divider-strong"/>
                <li className="context-footer company-selection-footer clear" role="menuitem">
                    <a className="log-out-link vismaicon vismaicon-sm vismaicon-dynamic vismaicon-logout"
                       id="logout_btn"
                       href={"#"}
                       onMouseDown={doLogout}>{props.translate("menu.userBox.logout")}</a>
                </li>
            </ul>
        </li>
    );

}

export default MenuUserBox;