import {useEffect, useState} from 'react';
import * as logger from 'utils/logger.function';
import {connect} from 'react-redux';
import {loadUserData} from './user.action';
import {useOidc} from "@axa-fr/react-oidc";


/**
 * wrapper component to keep user session active if he has the tab opened for some time and don't do anything in it
 *
 * behavior from old approval:
 *  1) each tab maintain it's state - if it's visible, or not
 *  2) if page is hidden, it does nothing
 *  3) when page gets focus from previously hidden, it will check against backend immediately
 *  4) when page is visible for some time, it should check against backend
 */


const KeepAlive = (props) => {

    const {logout, isAuthenticated} = useOidc();
    const [activeWindow, setActiveWindow] = useState(false);
    const [timerId, setTimerId] = useState(undefined);

    // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

    // Set the name of the hidden property and the change event for visibility
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }


    const handleVisibilityChange = () => {

        let isHiding = activeWindow && document[hidden];
        const newState = {
            activeWindow: !document[hidden]
        };

        if (isHiding) {
            // cancel timer
            window.clearInterval(timerId);
            // and remove it from state
            newState.timerId = undefined;


        } else {
            if (!isAuthenticated)
                return logout();

            // run the check
            checkUserSession("focus");
            // start timer
            newState.timerId = window.setInterval(function () {
                checkUserSession("timer");
                // eslint-disable-next-line
            }, ENVIRONMENT.KEEP_ALIVE_TIMEOUT_IN_SECONDS * 1000);
        }

        setTimerId(newState.timerId);
        setActiveWindow(newState.activeWindow);
    }

    useEffect(() => {
        const messageChannel = new BroadcastChannel("approval_channel");

        // we finished mounting, so we're in active window
        setActiveWindow(true);

        messageChannel.onmessage = (event) => {
            if (event.data === "logout")
                logout();
        };

        window.addEventListener("message",
            (event) => {
                if (event.data==="logout")
                    logout();
            },
            false,
        );

        return () => {
            // Handle page visibility change
            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        };

    });

    const checkUserSession = (reason) => {
        logger.debug(new Date(), reason, 'keepalive checking user session');
        props.loadUserData();
    }

}

export default connect(null, {loadUserData})(KeepAlive)